import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { useTranslation } from 'react-i18next';

import Head from '@/components/Head';
import NavBar from '@/components/NavBar';
import Footer from '@/components/Footer';
import BgImage from '@/components/BgImage';
import { NewsItemMeta } from '@/types';
import { NewsItem } from '@/components/NewsItem';

interface PageData {
  news: {
    nodes: { frontmatter: NewsItemMeta }[];
  };
}

const News: React.FC<PageProps<PageData>> = ({ data }) => {
  const { t } = useTranslation();
  console.log('News: ', data.news);

  return (
    <>
      <Head title={`${t('news.news')} | ${t('kota_olive_oil')}`} />

      <NavBar />

      {/* Hero with it's own bg image */}
      <main className="relative">
        <BgImage />
        <section className="relative py-32 pb-16 xl:pb-24 flex items-center flex-col">
          <h1 className="title-text uppercase">{t('news.news')}</h1>
          <hr className="text-gold-default w-1/2 mt-16 mb-8" />
        </section>
        {data.news.nodes.map((news) => (
          <NewsItem key={news.frontmatter.slug} {...news.frontmatter} />
        ))}
      </main>

      <Footer />
    </>
  );
};

export default News;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    news: allMdx(
      filter: { frontmatter: { lang: { eq: $language } } }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      nodes {
        frontmatter {
          title
          date(formatString: "YYYY-MM-DD")
          lang
          slug
          description
          previewImage {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                breakpoints: [480, 640, 768, 1024, 1280, 1536]
                quality: 90
                placeholder: BLURRED
              )
            }
          }
        }
      }
    }
  }
`;
