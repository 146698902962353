import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'gatsby-plugin-react-i18next';
import { GatsbyImage } from 'gatsby-plugin-image';

import { NewsItemMeta } from '@/types';
import { newsItemDescription } from './NewsItem.module.css';

export const NewsItem = ({
  title,
  description,
  date,
  slug,
  previewImage,
}: NewsItemMeta): ReactElement => {
  const { t } = useTranslation();

  return (
    <section className="relative w-full px-4 py-16 flex flex-wrap sm:flex-nowrap justify-center sm:justify-start items-center max-w-screen-lg mx-auto">
      <div className="w-full md:w-1/2 mb-5 sm:mb-0 px-10">
        {previewImage?.childImageSharp && (
          <GatsbyImage
            image={previewImage.childImageSharp.gatsbyImageData}
            alt="Boce ulja"
            loading="eager"
            style={{
              borderLeft: '1rem solid #d5a940',
              borderBottom: '2px solid #d5a940',
            }}
          />
        )}
      </div>
      <div className="text-center sm:text-left w-full md:w-1/2">
        <h2 className="mb-4 text-3xl xl:text-4xl text-gold-default font-serif">
          {title}
        </h2>
        <h3 className="text-white font-serif mb-4 text-1xl xl:text-lg">
          {date}
        </h3>
        <hr className="text-gold-default w-full my-4" />
        {description && (
          <div
            className={`text-white text-1xl xl:text-lg mb-4 ${newsItemDescription}`}
          >
            {description}
          </div>
        )}
        <Link className="btn mt-4" to={`/news/${slug}`}>
          {t('news.read')}
        </Link>
      </div>
    </section>
  );
};
